import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'

const Wrapper = styled.div`
  width: 100%;
  margin: -1.5rem auto 2.5rem;
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: 0 1.5rem;
  display: flex;
  justify-content: flex-end;
`

const Button = styled(Link)`
  background: transparent;
  color: ${props => props.theme.colors.primary};
  padding: 0.7rem 1rem;
  border: 1px solid ${props => props.theme.colors.highlight};
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.2s all;
  &:hover {
    background: ${props => props.theme.colors.primary};
    color: white;
  }
  @media (hover: none) {
    &:active {
      opacity: 0.8;
      border: 1px solid ${props => props.theme.colors.primary};
    }
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    border: none;
  }
`

const Pagination = props => {
  return (
    <>
      <Wrapper>
        <div>
          {props.context.previousPagePath && (
            <Button
              to={`${props.context.previousPagePath}`}
              aria-label="Page précédente"
            >
              <span>&larr;</span>
            </Button>
          )}
          {props.context.nextPagePath && (
            <Button
              style={{ order: 3 }}
              to={`${props.context.nextPagePath}`}
              aria-label="Page suivante"
            >
              <span>&rarr;</span>
            </Button>
          )}
        </div>
      </Wrapper>
    </>
  )
}

export default Pagination
