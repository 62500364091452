import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { FaFilm } from 'react-icons/fa'

const Post = styled.li`
  position: relative;
  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: 2px;
  margin: 0 0 1rem 0;
  width: 100%;
  transition: background 0.2s;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 32%')};
  }
  &:hover {
    background: ${props => props.theme.colors.tertiary};
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.text};
    text-decoration: none;
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
      @media screen and (min-width: ${props => props.theme.responsive.small}) {
        padding-bottom: ${props => (props.featured ? '40%' : '60%')};
      }
    }
    p:nth-of-type(1) {
      font-size: 0.7rem;
      @media screen and (min-width: 975px) {
        font-size: 0.9rem;
      }
    }
  }
`

const StyledImg = styled(Img)`
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
`

const Title = styled.h2`
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: capitalize;
  margin: 1rem 0 4px 1rem;
  color: ${props => props.theme.colors.title};
  font-family: ${props => props.theme.fonts.titles};
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    font-size: 1.5rem;
  }
`

const Infos = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 1rem 0.5rem 1rem;
  color: gray;
  svg {
    vertical-align: middle;
  }
`

const Tags = styled.p`
  margin: 0 1rem 1rem 1rem;
  line-height: 1.6;
  font-style: italic;
  font-size: 1rem;
`
const Excerpt = styled.p`
  margin: 0.2rem 1rem 1rem 1rem;
  line-height: 1.6;
  font-style: italic;
  font-size: 0.75rem;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    font-size: 1rem;
  }
`

const Card = ({
  slug,
  heroImage,
  title,
  publishDate,
  body,
  categories,
  dateDeSortie,
  realisateurices,
  coupsDeCoeur,
  tags,
  genre,
  ...props
}) => {
  return (
    <>
      {heroImage && body && (
        <Post featured={props.featured}>
          <Link to={`${props.basePath}/${slug}/`}>
            <StyledImg fluid={heroImage.fluid} backgroundColor={'#eeeeee'} />
            <Title>{title}</Title>
            <Infos>
              <FaFilm />
              &nbsp;
              {categories.nom}
              &nbsp;
              {genre && (
                <>
                  /&nbsp;
                  {genre.slice(0, 1)[0].title}
                  &nbsp;
                </>
              )}
              ({dateDeSortie})
            </Infos>
            {tags ? (
              <Tags>{`${tags.map(tags => tags.title).join(', ')}.`}</Tags>
            ) : (
              <Excerpt
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.excerpt,
                }}
              />
            )}
          </Link>
        </Post>
      )}
    </>
  )
}

export default Card
